import React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children, data }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header>
        <div className="common-header">
          <Link className="header-link header-font" to="/">
            { title ||"pilefort"}
          </Link>
          <div className="menus">
            <Link className="header-link header-font" to="/about">
              About
            </Link>
          </div>
        </div>
      </header>
      <div id="reading-progress" aria-hidden="true"></div>
      <div className="subHeader">
        <div>
          <p>{data}</p>
        </div>
      </div>
      <div className="container">
        <div className="mainContents">
          <main>{children}</main>
        </div>
      </div>

      <footer className="footer">
        <p className="footer-font">Pilefort</p>
        <p className="footer-font">All rights reserved 2020.</p>
        <div className="social-icons">
          <a
            href="https://twitter.com/KSKB_TKY"
            className="white-twitter"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 512 512"
            >
              <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
            </svg>
          </a>
          <a
            href="https://github.com/pilefort"
            className="white-twitter"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 23 23"
              role="img"
              aria-labelledby="a7oztecq7kq16m7v3uubd8veo3moz9hd"
              className="crayons-icon"
            >
              <title>Github</title>
              <path d="M12 2C6.475 2 2 6.475 2 12a9.994 9.994 0 006.838 9.488c.5.087.687-.213.687-.476 0-.237-.013-1.024-.013-1.862-2.512.463-3.162-.612-3.362-1.175-.113-.288-.6-1.175-1.025-1.413-.35-.187-.85-.65-.013-.662.788-.013 1.35.725 1.538 1.025.9 1.512 2.338 1.087 2.912.825.088-.65.35-1.087.638-1.337-2.225-.25-4.55-1.113-4.55-4.938 0-1.088.387-1.987 1.025-2.688-.1-.25-.45-1.275.1-2.65 0 0 .837-.262 2.75 1.026a9.28 9.28 0 012.5-.338c.85 0 1.7.112 2.5.337 1.912-1.3 2.75-1.024 2.75-1.024.55 1.375.2 2.4.1 2.65.637.7 1.025 1.587 1.025 2.687 0 3.838-2.337 4.688-4.562 4.938.362.312.675.912.675 1.85 0 1.337-.013 2.412-.013 2.75 0 .262.188.574.688.474A10.016 10.016 0 0022 12c0-5.525-4.475-10-10-10z" />
            </svg>
          </a>
          <a
            href="https://zenn.dev/pilefort"
            className="white-twitter"
            target="_blank"
            rel="noreferrer"
          >
            <svg viewBox="0 0 377.4 88.3" width="98" height="23">
              <g fill="#111">
                <path d="M233 56.8h-39c.5 3.5 2.2 6.8 4.8 9.2 2.7 2.3 6.2 3.5 9.8 3.4 2.8 0 5.6-.5 8.2-1.7 2.5-1.1 4.8-2.8 6.5-5l8.2 9.5c-2.5 3.4-5.7 6.1-9.5 7.9-4.6 2.2-9.6 3.3-14.7 3.2-5.7.1-11.4-1.2-16.5-4-4.5-2.5-8.2-6.3-10.7-10.9s-3.8-9.8-3.7-15.1v-2.2c-.1-5.7 1.1-11.3 3.5-16.5 2.2-4.7 5.7-8.6 10.1-11.3 4.7-2.8 10.1-4.2 15.5-4.1 5.2-.1 10.3 1.1 14.9 3.7 4.1 2.5 7.4 6.2 9.4 10.5 2.2 5.1 3.3 10.5 3.2 16.1v7.3zm-16.9-12.9c.1-2.9-.9-5.7-2.8-7.9-1.8-1.9-4.4-2.9-7.9-2.9-2.9-.1-5.8 1.1-7.7 3.2-2 2.6-3.3 5.7-3.6 9h22v-1.4zM128.3 67.9h36.1v14.7h-56.9V72l35.8-54.3h-36.2V2.9h56.6v10.4l-35.4 54.6zM248.8 50.7c0-19.1 12.7-29.2 28.2-29.2s27.9 10.1 27.9 29.2V82h-16V51.4c0-10.6-4.8-16.1-12-16.1s-12.4 5.5-12.4 16.1v30.7h-15.8l.1-31.4zM320.3 50.7c0-19.1 12.7-29.2 28.2-29.2s27.9 10.1 27.9 29.2V82h-16V51.4c0-10.6-4.8-16.1-12-16.1S336 40.8 336 51.4v30.7h-15.8l.1-31.4z"></path>
              </g>
              <path
                fill="#3EA8FF"
                className="st0"
                d="M2.4 83.3h17c.9 0 1.7-.5 2.2-1.2L68.4 5.2c.6-1-.1-2.2-1.3-2.2H51c-.8 0-1.5.4-1.9 1.1L1.6 81.9c-.3.6.1 1.4.8 1.4zM61 82.1l22.1-35.5c.7-1.1-.1-2.5-1.4-2.5h-16c-.6 0-1.2.3-1.5.8L41.5 81.2c-.6.9.1 2.1 1.2 2.1H59c.8 0 1.6-.4 2-1.2z"
              ></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
